import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { GiHamburger } from "react-icons/gi"
import { GrFormClose } from "react-icons/gr"
import { gsap } from "gsap"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Nav = () => {
  const [open, setOpen] = useState(false)

  const toggleNav = () => {
    setOpen(!open)
  }

  // Setting the Icon type based on the state
  const icon = () => {
    if (open) {
      return <GrFormClose />
    } else {
      return <GiHamburger />
    }
  }

  return (
    <>
      <div
        id="menu-toggle"
        onClick={toggleNav}
        className={`menu ${open ? "open" : "closed"}`}
      >
        {/* call the icon function */}
        {icon()}
      </div>
      <nav className={`main-nav ${open ? "open" : "closed"}`}>
        <AnchorLink id="about-link" className="nav-link" href={"#about"}>
          About
        </AnchorLink>
        <AnchorLink className="nav-link" href={"#projects"}>
          Projects
        </AnchorLink>
        <AnchorLink className="nav-link" href={"#resume"}>
          Resume
        </AnchorLink>
        <AnchorLink className="nav-link" href={"#contact"}>
          Contact
        </AnchorLink>
      </nav>
      <script></script>
    </>
  )
}

export default Nav
