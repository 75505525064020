import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const Success = () => (
  <Layout>
    <h1>Contact</h1>
    <p>Thank you for your submission!</p>
    <Link to="/">Back to home</Link>
  </Layout>
)

export default Success
