import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "./../sass/styles.scss"
import SiteMeta from "./SiteMeta"
import {TransitionState} from "gatsby-plugin-transition-link"
import { motion } from "framer-motion"


const Layout = ({ children }) => {
  return (
    <>
      <SiteMeta />
        <TransitionState>
            {({transitionStatus, entry, exit}) => (
                <motion.div
                  id="home"
                  initial={entry.state}
                  animate={transitionStatus === "exiting" ? exit.state : {opacity: 1, x: 0, y: 0}}
                  transition={transitionStatus === 'exiting' ? {duration: exit.length} : {duration: 0.4}}>
                    <Header/>
                    <main className="test">{children}</main>
                    <Footer/>
                </motion.div>
            )}
        </TransitionState>
    </>
  )
}

export default Layout
