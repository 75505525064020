import React from "react"
import Layout from "../components/Layout"
import About from "../components/About"
import Projects from "../components/Projects"
import Resume from "../components/Resume"
import Contact from "../components/Contact"
import ProjectsLoader from "../components/ProjectsLoader";

export default function Home() {
  return (
    <Layout>
      <About />
      <ProjectsLoader />
      <Resume />
      <Contact />
    </Layout>
  )
}
