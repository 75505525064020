import React from "react"
import Wordloop from "./Wordloop"

const About = () => {

  return (
    <section id="about" className="about">
      <h1>
        Hello, my name is Tom Dorozenko,
          <br /><span id={"info"}>I like to dabble in <Wordloop /></span>
      </h1>
    </section>
  )
}

export default About
