import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { IoIosArrowRoundBack } from "react-icons/io"
import { GoLinkExternal } from "react-icons/go"
import BlockContent from "@sanity/block-content-to-react"
import { motion } from "framer-motion"
import TransitionLink from "gatsby-plugin-transition-link"
import { MDXRenderer } from "gatsby-plugin-mdx"

const projectTemplate = ({ data, transitionStatus, entry, exit }) => {
  const {
    mdx: {
      frontmatter: { image, slug, url },
      body,
    },
  } = data
  console.log("exit", entry)

  const article = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.4,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, x: 150 },
    show: { opacity: 1, x: 0 },
  }

  return (
    <>
      {typeof window !== "undefined" && (
        <motion.div
          className="page-wrap"
          initial={entry.state}
          animate={
            transitionStatus === "exiting"
              ? { x: window.innerWidth, opacity: 0 }
              : { x: 0 }
          }
          transition={{ duration: 0.4 }}
          exit={{ x: -window.innerWidth }}
        >
          {!!image.childImageSharp && (
            <Image fluid={image.childImageSharp.fluid} />
          )}
          <motion.article
            className="project-description"
            variants={article}
            initial={"hidden"}
            animate={"show"}
          >
            <motion.nav className="project-nav" variants={item}>
              <TransitionLink
                className="home-button"
                to="/"
                exit={{
                  length: 0.4,
                  state: { x: -window.innerWidth, opacity: 0 },
                }}
                entry={{
                  delay: 0.4,
                  state: { x: -window.innerWidth, opacity: 1 },
                }}
              >
                <IoIosArrowRoundBack /> Back to Home
              </TransitionLink>
            </motion.nav>
            <motion.div variants={item}>
              <MDXRenderer>{body}</MDXRenderer>
            </motion.div>
            {!!url && (
              <motion.a
                href={url}
                target="_blank"
                rel="noreferrer"
                variants={item}
              >
                Go to Website
                <GoLinkExternal />
              </motion.a>
            )}
          </motion.article>
        </motion.div>
      )}
    </>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        url
      }
      body
    }
  }
`
export default projectTemplate
