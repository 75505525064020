import React, { Component } from "react"

const Contact = () => (
  <section id="contact" className="contact">
    <div className="form-wrap">
      <h4>Get in touch</h4>
      <h2>Drop me an email.</h2>
      <form
        name="Contact Form"
        method="POST"
        data-netlify="true"
        id="contact-form"
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <div>
          <input type="name" name="name" placeholder="Name" />
        </div>
        <div>
          <input type="email" name="email" placeholder="Email" />
        </div>
        <div>
          <textarea name="message" placeholder="Message" />
        </div>
        <br />
        <button type="submit">Send</button>
      </form>
    </div>
  </section>
)

export default Contact
