import React, { useEffect, useState } from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { Link } from "gatsby"
import { GatsbyImageSharpFixed } from "gatsby-transformer-sharp/src/fragments"
import GatsbyImage from "gatsby-image"
import { set } from "mobx"

const Projects = ({ image }) => {
  const data = useStaticQuery(graphql`
    {
      allMdx {
        nodes {
          frontmatter {
            title
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          id
        }
      }
    }
  `)

  const {
    allMdx: { nodes: posts },
  } = data

  return (
    <>
      {typeof window !== "undefined" && (
        <section id="projects" className="projects">
          <h4>Work</h4>
          <h2>Selected Website Projects</h2>
          <div className="project-wrap">
            {posts.map(post => {
              return (
                <article className="project" key={post.id}>
                  <TransitionLink
                    to={`/projects/${post.frontmatter.slug}`}
                    exit={{
                      length: 0.4,
                      state: { x: -window.innerWidth, opacity: 0 },
                    }}
                    entry={{
                      delay: 0.4,
                      state: { x: window.innerWidth, opacity: 1 },
                    }}
                  >
                    <Image
                      fluid={
                        !!post.frontmatter.image &&
                        post.frontmatter.image.childImageSharp.fluid
                      }
                      className="image"
                    />
                    <div className="details">
                      <h2 className="title">{post.frontmatter.title}</h2>
                    </div>
                  </TransitionLink>
                </article>
              )
            })}
          </div>
        </section>
      )}
    </>
  )
}

export default Projects
