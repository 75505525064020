import React from "react"
import resume from "../files/Tom-Dorozenko-Resume.pdf"
import { GrDocumentDownload } from "react-icons/gr"

const Resume = () => {
  return (
    <section id="resume" className="resume">
      <div className="download">
        <h4>Want to see my credentials?</h4>
        <h2>Current Resume</h2>
        <a className="download" href={resume} target="_blank" rel="noreferrer" download>
          <GrDocumentDownload /> Download
        </a>
      </div>
    </section>
  )
}

export default Resume
