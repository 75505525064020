import { observable } from "mobx"
import React, { Component } from "react"
import Typist from "react-typist";

class Wordloop extends Component {
  @observable _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      skillsText: "Front End Development",
    }
  }

  // componentDidMount() {
  //   this._isMounted = true
  //   let skills = [
  //       "HTML",
  //       "CSS",
  //       "JavaScript",
  //       "SASS",
  //       "Design",
  //       "React",
  //       "Wordpress",
  //       "React Native",
  //       "Juggling",
  //       "Gatsby",
  //       "Grunt",
  //       "Jazz Flute",
  //       "GSAP Animations",
  //       "jQuery",
  //       "GraphQL",
  //       "MobX",
  //       "TypeScript",
  //       "Figma"
  //     ],
  //     currentIndex = 0,
  //     maxNumTimes = -1,
  //     numTimesRan = 0
  //
  //   let interval = setInterval(() => {
  //     if (this._isMounted && maxNumTimes !== 0) {
  //       this.setState({
  //         skillsText: skills[currentIndex],
  //       })
  //       currentIndex++
  //
  //       if (currentIndex > skills.length - 1) {
  //         if (maxNumTimes === -1) {
  //           currentIndex = 0
  //         } else {
  //           numTimesRan++
  //           if (numTimesRan === maxNumTimes) {
  //             clearInterval(interval)
  //           } else {
  //             currentIndex = 0
  //           }
  //         }
  //       }
  //     }
  //   }, 2000)
  // }
  //
  // componentWillUnmount() {
  //   this._isMounted = false
  // }

  render() {

      return (
          <>
              <Typist>
                  <span>Front End Web Development</span>
                  <Typist.Backspace count={30} delay={200} />
                  <span>UI/UX Design</span>
                  <Typist.Backspace count={12} delay={200} />
                  <span>HTML</span>
                  <Typist.Backspace count={4} delay={200} />
          <span>CSS</span>
                  <Typist.Backspace count={3} delay={200} />
              <span>JavaScript</span>
                  <Typist.Backspace count={10} delay={200} />
                  <span>SASS</span>
                  <Typist.Backspace count={4} delay={200} />
                          <span>React</span>
                  <Typist.Backspace count={5} delay={200} />
                  <span>React Native</span>
                  <Typist.Backspace count={12} delay={200} />
                  <span>TypeScript</span>
                  <Typist.Backspace count={10} delay={200} />
                  <span>MobX</span>
                  <Typist.Backspace count={4} delay={200} />
                  <span>Juggling</span>
                  <Typist.Backspace count={9} delay={200} />
                              <span>Wordpress</span>
                  <Typist.Backspace count={9} delay={200} />
                                          <span>Gatsby</span>
                  <Typist.Backspace count={6} delay={200} />
                  <span>Sanity</span>
                  <Typist.Backspace count={6} delay={200} />
                                                  <span>Jazz Flute</span>
                  <Typist.Backspace count={10} delay={200} />
                     <span>GSAP Animations</span>
                  <Typist.Backspace count={15} delay={200} />
                  <span>Framer Motion</span>
                  <Typist.Backspace count={13} delay={200} />
                  <span>Figma</span>
                  <Typist.Backspace count={5} delay={200} />
                  <span>to be continued...</span>

              </Typist>
    </>

      )
  }
}

export default Wordloop
