import React from "react"

const Footer = () => {
  return (
    <footer className="foot">
      &copy; Tom Dorozenko {new Date().getFullYear()}
    </footer>
  )
}

export default Footer
