import React from 'react'

interface videoProps {
    videoSrcURL: string
    videoTitle: string
}

const Video = (props: videoProps) => (
  <div className="video">
    <iframe
      src={props.videoSrcURL}
      title={props.videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video