import React from "react"
import Nav from "./Nav"

const Header = () => {
  return (
    <header className="title-nav">
      <svg
        id="logo-full"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3071.82 360.6"
      >
        <path
          d="M211.66,194.9h41.85V131.45h54V194.9h64.34v48.6H307.51v85.95c0,26.1,13.49,33.75,27.89,33.75,12.15,0,22.5-7.65,22.5-7.65l19.8,44.1s-20.25,14-51.3,14c-41.39,0-72.89-30.15-72.89-75.6V243.5H211.66Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M629.25,301.55c0,62.55-50.4,112.05-113.85,112.05s-113.85-49.5-113.85-112c0-63,50.4-112,113.85-112S629.25,238.55,629.25,301.55Zm-54.9,0c0-33.3-22.5-61.65-59-61.65s-58.95,28.35-58.95,61.65S479,363.2,515.4,363.2,574.35,334.85,574.35,301.55Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M991,271V408.2h-54V284.9c0-29.25-11.25-45-38.25-45-28.35,0-41.85,27.45-41.85,27.45V408.2H803V284.9c0-29.25-11.25-45-38.25-45-28.35,0-41.85,27.45-41.85,27.45V408.2h-54V194.9h50.4v31s19.35-36.45,63-36.45c51.3,0,66.15,37.35,66.15,37.35s18-37.35,68-37.35C959.55,189.5,991,219.65,991,271Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M1381.19,408.2h-50V380.3s-18.45,33.3-64.35,33.3c-55.35,0-104-46.35-104-112s48.6-112,104-112c40.5,0,60.3,27.9,60.3,27.9V68h54Zm-54-138.6s-15.3-29.7-50.85-29.7c-36.45,0-58.5,26.55-58.5,61.65s22.05,61.65,58.5,61.65c35.55,0,50.85-29.7,50.85-29.7Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M1654.79,301.55c0,62.55-50.4,112.05-113.85,112.05s-113.85-49.5-113.85-112c0-63,50.4-112,113.85-112S1654.79,238.55,1654.79,301.55Zm-54.9,0c0-33.3-22.5-61.65-59-61.65s-59,28.35-59,61.65,22.5,61.65,59,61.65S1599.89,334.85,1599.89,301.55Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M1840.63,196.25l-18.9,48.6s-9.89-4.95-25.64-4.95c-32.85,0-47.7,28.8-47.7,28.8V408.2h-54V194.9h51.3v31s18.44-36.45,58-36.45C1823.09,189.5,1840.63,196.25,1840.63,196.25Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M2074.64,301.55c0,62.55-50.4,112.05-113.85,112.05s-113.85-49.5-113.85-112c0-63,50.4-112,113.85-112S2074.64,238.55,2074.64,301.55Zm-54.9,0c0-33.3-22.5-61.65-59-61.65s-59,28.35-59,61.65,22.5,61.65,59,61.65S2019.74,334.85,2019.74,301.55Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M2298.29,194.9,2192.54,357.8h100.79v50.4H2100.74l105.74-162.9h-98.1V194.9Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M2537.23,300.65c0,4.05,0,12.15-.45,20.25h-159.3c4.05,18.9,26.1,43.2,59.85,43.2,37.35,0,58.5-21.6,58.5-21.6l27,40.5s-33.75,30.6-85.5,30.6c-64.8,0-114.75-49.5-114.75-112s45.45-112,107.1-112C2488.18,189.5,2537.23,235,2537.23,300.65Zm-54-18.9c-.9-18.45-18-43.2-53.55-43.2-34.65,0-50.4,24.3-52.2,43.2Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M2769.43,270.05V408.2h-54V283.55c0-30.6-18.9-43.65-42.3-43.65-27.9,0-42.3,27.45-42.3,27.45V408.2h-54V194.9h50.4v31s18.9-36.45,63.9-36.45C2732.53,189.5,2769.43,218.75,2769.43,270.05Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M2883.72,279.05l76.5-84.15H3030l-93.6,100.8,99.9,112.5h-71.1l-81.45-93.15V408.2h-54V68h54Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
        <path
          d="M3268.47,301.55c0,62.55-50.4,112.05-113.85,112.05s-113.85-49.5-113.85-112c0-63,50.4-112,113.85-112S3268.47,238.55,3268.47,301.55Zm-54.9,0c0-33.3-22.5-61.65-59-61.65s-58.95,28.35-58.95,61.65,22.5,61.65,58.95,61.65S3213.57,334.85,3213.57,301.55Z"
          transform="translate(-204.16 -60.5)"
          styles={"stroke:#000;stroke-miterlimit:10;stroke-width:15px"}
        />
      </svg>

      <svg
        id="logo-small"
        x="0px"
        y="0px"
        viewBox="0 0 750 750"
        styles={"enable-background:new 0 0 750 750;"}
      >
        <g>
          <path
            styles={"stroke:#000000;stroke-width:15;stroke-miterlimit:10;"}
            d="M158.4,320.9h41.8v-63.4h54v63.4h64.3v48.6h-64.3v86c0,26.1,13.5,33.7,27.9,33.7c12.1,0,22.5-7.6,22.5-7.6
		l19.8,44.1c0,0-20.3,14-51.3,14c-41.4,0-72.9-30.2-72.9-75.6v-94.5h-41.8V320.9z"
          />
          <path
            className="st0"
            d="M566.6,534.2h-49.9v-27.9c0,0-18.5,33.3-64.3,33.3c-55.3,0-103.9-46.4-103.9-112c0-65.7,48.6-112,103.9-112
		c40.5,0,60.3,27.9,60.3,27.9V194h54V534.2z M512.6,395.6c0,0-15.3-29.7-50.8-29.7c-36.5,0-58.5,26.6-58.5,61.6s22,61.6,58.5,61.6
		c35.5,0,50.8-29.7,50.8-29.7V395.6z"
          />
        </g>
      </svg>

      <Nav />
    </header>
  )
}

export default Header
