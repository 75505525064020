import Projects from "./Projects";
import React, {Suspense, useEffect} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import ReactLoading from 'react-loading';

@observer
class ProjectsLoader extends React.Component {
    @observable rendered = false

    componentDidMount() {
        this.rendered = true
    }

    render() {
        console.log('mounted' ,this.rendered)
        return (
            <>
                {!!this.rendered ? <Projects/> : <section id={'projects'}>
                    <h4>Work</h4>
                    <h2>Selected Website Projects</h2>
                    <div id={"projectLoader"}>
                        <ReactLoading type={"bubbles"} color={'black'} height={100} width={100} />
                    </div>
                    </section>
                }</>

        )
    }

}

export default ProjectsLoader